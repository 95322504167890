import axios from "@/plugins/Axios";

const model = "document-types";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(document_type) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, document_type);
}

function update(id, document_type) {
  return axios.put(
    `${process.env.VUE_APP_API_URL}/${model}/${id}`,
    document_type
  );
}

export { all, find, create, remove, update };
