<template>
  <div class="container">
    <c-card title="Crear DOF">
      <form method="post" id="mytextarea" @submit.prevent="saveDof">
        <!-- <button type="submit">Guardar</button>-->
        <div class="mb-3">
          <label for="title" class="form-label">Titulo:</label>
          <input v-model="dof.title" class="form-control" id="title" required />
        </div>
        <div class="mb-3">
          <label for="dependency" class="form-label">Dependencia:</label>
          <select
            id="dependency"
            class="form-select"
            v-model="dof.dependency_id"
            required
          >
            <option
              v-for="dependency in dependencies"
              :value="dependency.id"
              :key="dependency.id"
            >
              {{ dependency.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="state" class="form-label">Tipo de Documento:</label>
          <select
            id="state"
            class="form-select"
            v-model="dof.document_type_id"
            required
          >
            <option
              v-for="document_type in document_types"
              :value="document_type.id"
              :key="document_type.id"
            >
              {{ document_type.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="resume" class="form-label">Resumen:</label>
          <textarea
            v-model="dof.resume"
            class="form-control"
            id="resume"
            required
            rows="3"
          />
        </div>
        <div class="mb-3">
          <label for="oficialPublishDate" class="form-label"
            >Fecha de publicación oficial:</label
          >
          <input
            v-model="dof.fecha_publicacion_oficial"
            type="date"
            class="form-control"
            id="oficialPublishDate"
            required
          />
        </div>
        <div class="mb-3">
          <label for="applicationDate" class="form-label"
            >Fecha de aplicación:</label
          >
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="radioDate"
                id="radioDate"
                :value="true"
                v-model="publicacionEnabledDate"
              />
              <label class="form-check-label" for="radioDate">Fecha</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="radioDate"
                id="radioNA"
                :value="false"
                v-model="publicacionEnabledDate"
              />
              <label class="form-check-label" for="radioNA">NA</label>
            </div>
          </div>
          <input
            v-if="publicacionEnabledDate"
            v-model="dof.fecha_entrada_en_vigor"
            type="date"
            class="form-control mt-2"
            id="applicationDate"
            required
          />
        </div>
        <div class="mb-3">
          <label for="comments" class="form-label">Comentarios:</label>
          <textarea
            v-model="dof.comments"
            class="form-control"
            id="comments"
            required
            rows="5"
          />
        </div>
        <div class="mb-3">
          <label for="link" class="form-label">Link Oficial:</label>
          <input v-model="dof.link" class="form-control" id="link" required />
        </div>
        <editor
          :api-key="`${api_key_editor}`"
          :init="{
            height: 500,
            menubar: false,
            selector: '#mytextarea',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="dof.content"
          output-format="html"
        />
        <c-primary-button class="w-100 mt-3" type="submit">
          Guardar
        </c-primary-button>
      </form>
    </c-card>
  </div>
</template>

<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { create } from "@/services/dofs";
import { all as allDocumentTypes } from "@/services/document_types";
import { all as allDependencies } from "@/services/dof_dependencies";

let Editor = require("@tinymce/tinymce-vue").default;

export default {
  name: "app",
  components: {
    editor: Editor,
    CCard,
    CPrimaryButton,
  },
  mounted() {
    this.getDocumentTypes();
    this.getDependencies();
  },
  data() {
    return {
      api_key_editor: process.env.VUE_APP_API_EDITOR_KEY,
      document_types: [],
      dependencies: [],
      dof: {
        document_type_id: "",
        dependency_id: "",
        title: "",
        resume: "",
        content: "",
        fecha_publicacion_oficial: "",
        fecha_entrada_en_vigor: "",
        comments: "",
        link: "",
      },
      publicacionEnabledDate: true,
    };
  },
  methods: {
    getDocumentTypes() {
      allDocumentTypes().then((response) => {
        this.document_types = response.data;
      });
    },
    getDependencies() {
      allDependencies().then((response) => {
        this.dependencies = response.data;
      });
    },
    saveDof() {
      if (!this.publicacionEnabledDate) {
        this.dof.fecha_entrada_en_vigor = null;
      }
      create(this.dof).then(() => {
        this.$router.push(`/admin/dofs`);
      });
    },
  },
};
</script>

<style scoped></style>
