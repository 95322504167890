import axios from "@/plugins/Axios";

const model = "dofs";

function all({ ...params }) {
  let paramsURL = "";
  for (let param in params) {
    paramsURL += `${param}=${params[param]}`;
  }
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}?${paramsURL}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(dof) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, dof);
}

function update(id, dof) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, dof);
}

export { all, find, create, remove, update };
